import "./App.css";
import Clock from "./components/clock/Clock";
import Footer from "./components/footer/Footer"

function App() {
  return (
    <div className="App">
      <header>
        <h1>Цифровые часики</h1>
        <h2>Цель была написать, не используя гугл, и не заглядывая в то, что уже писал ранее, залез в гугл только вспомнить которое округление идет в меньшую сторону (по сути, мог проверить и через консоль)</h2>
      </header>
      <main>
        <Clock />
      </main>
      <Footer />
    </div>
  );
}

export default App;
