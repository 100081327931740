import './footer.css'
import github from '../../ico/github.png'
import telegram from '../../ico/telegram.png'
import gmail from '../../ico/gmail.png'
import pycel from '../../ico/pycel.png'


const Footer = () => {
    return (
        <footer>
            <div className='box'>
                <a href="https://github.com/pycel89/clock" target="_blank"><img src={github}></img>https://github.com/pycel89/clock</a>
                <a href="http://t.me/pycel" target="_blank"><img src={telegram}></img>http://t.me/pycel</a>
                <a href="mailto:pycel89@gmail.com" target="_blank"><img src={gmail}></img>pycel89@gmail.com</a>
            </div>
            <div className='box'><img height='100px' src={pycel}></img></div>
        </footer>
    )
}
export default Footer;